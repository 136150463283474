<template>
  <div class="d-flex justify-space-between align-center wrapper">
    <div
      v-if="!isMobile"
      class="markero-logo col-6 pa-0 px-10"
    >
      <v-img
        alt="Company Registration Logo"
        :src="require('@/../public/img/markero-registration.png')"
        contain
      />
    </div>
    <div class="col-12 col-sm-6 product-finder">
      <div
        v-if="activeStep !== 'generate'"
        :style="cssProps"
        class="col px-0 mt-2 stepper-wrapper"
      >
        <div class="row justify-center">
          <div class="stepper">
            <div
              class="step pointer"
              @click="activeStep = 'basic-info'"
            >
              <div
                class="step-number"
                :class="{
                  'active': activeStep === 'basic-info',
                  'finished': activeStep !== 'basic-info' && activeStep !== 'generate'
                }"
              >
                <span
                  v-if="activeStep === 'basic-info'"
                  :style="{color: primaryColor}"
                >01</span>
                <span
                  v-else
                  class="mdi mdi-check"
                />
              </div>
              <div
                class="step-name"
                :style="{color: primaryColor}"
              >
                {{ $t('product-finder.stepper.step-one-title') }}
              </div>
            </div>
            <div
              class="line"
              :style="lineStyle"
            />
            <div
              class="step pointer"
              @click="activeStep = 'additional-info'"
            >
              <div
                class="step-number"
                :class="{
                  'active': activeStep === 'additional-info',
                  'next': activeStep !== 'additional-info'
                }"
              >
                02
              </div>
              <div
                class="step-name"
                :style="{
                  'font-weight': activeStep === 'additional-info' ? '700' : '400',
                  color: activeStep === 'additional-info' ? primaryColor : '#252525'
                }"
              >
                {{ $t('product-finder.stepper.step-two-title') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductFinder
        :active-step="activeStep"
        @updateActiveStep="updateActiveStep"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ProductFinder: () => import('@/components/product-finder/ProductFinder.vue')
  },
  data () {
    return {
      activeStep: 'basic-info'
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary,
        '--dark-grey': this.$vuetify.theme.themes.light.markero.darkGrey
      }
    },
    primaryColor () {
      return this.$vuetify.theme.themes.light.primary
    },
    lineStyle () {
      return {
        'background-color': this.activeStep === 'basic-info' ? '#E4E4E4' : this.primaryColor
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    updateActiveStep (val) {
      this.activeStep = val
    }
  }
}

</script>

<style scoped>
  .wrapper {
    background-color: white;
  }
  .wrapper, .markero-logo, .product-finder {
    height: 100vh;
  }
  .markero-logo {
    background-color: #F9B004;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  /* Stepper Wrapper Styles */
  .stepper-wrapper {
    margin-bottom: 10%;
  }

  /* Stepper Styles */
  .stepper {
    display: flex;
    align-items: center;
  }

  /* Step Styles */
  .step {
    width: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* Step Number Styles */
  .step-number {
    width: 30px;
    height: 30px;
    font-size: 12px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .step-number.active {
    background-color: #FFF1E2;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
  }

  .step-number.finished {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }

  .step-number.next {
    border: 2px solid #E4E4E4;
    color: var(--dark-grey);
  }

  /* Step Name Styles */
  .step-name {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
  }

  /* Line Styles */
  .line {
    width: 80px;
    height: 2px;
    margin-top: -40px;
  }

  /* Pointer Class */
  .pointer {
    cursor: pointer;
  }

  @media only screen and (max-width: 450px) {
    .stepper-wrapper {
      margin-bottom: 30px;
    }
  }
</style>
