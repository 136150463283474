var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between align-center wrapper"},[(!_vm.isMobile)?_c('div',{staticClass:"markero-logo col-6 pa-0 px-10"},[_c('v-img',{attrs:{"alt":"Company Registration Logo","src":require('@/../public/img/markero-registration.png'),"contain":""}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-sm-6 product-finder"},[(_vm.activeStep !== 'generate')?_c('div',{staticClass:"col px-0 mt-2 stepper-wrapper",style:(_vm.cssProps)},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"stepper"},[_c('div',{staticClass:"step pointer",on:{"click":function($event){_vm.activeStep = 'basic-info'}}},[_c('div',{staticClass:"step-number",class:{
                'active': _vm.activeStep === 'basic-info',
                'finished': _vm.activeStep !== 'basic-info' && _vm.activeStep !== 'generate'
              }},[(_vm.activeStep === 'basic-info')?_c('span',{style:({color: _vm.primaryColor})},[_vm._v("01")]):_c('span',{staticClass:"mdi mdi-check"})]),_c('div',{staticClass:"step-name",style:({color: _vm.primaryColor})},[_vm._v(" "+_vm._s(_vm.$t('product-finder.stepper.step-one-title'))+" ")])]),_c('div',{staticClass:"line",style:(_vm.lineStyle)}),_c('div',{staticClass:"step pointer",on:{"click":function($event){_vm.activeStep = 'additional-info'}}},[_c('div',{staticClass:"step-number",class:{
                'active': _vm.activeStep === 'additional-info',
                'next': _vm.activeStep !== 'additional-info'
              }},[_vm._v(" 02 ")]),_c('div',{staticClass:"step-name",style:({
                'font-weight': _vm.activeStep === 'additional-info' ? '700' : '400',
                color: _vm.activeStep === 'additional-info' ? _vm.primaryColor : '#252525'
              })},[_vm._v(" "+_vm._s(_vm.$t('product-finder.stepper.step-two-title'))+" ")])])])])]):_vm._e(),_c('ProductFinder',{attrs:{"active-step":_vm.activeStep},on:{"updateActiveStep":_vm.updateActiveStep}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }